export const sliderData = [
    {
        sliderhaed:"Welcome to Connex",
        sliderPara:"Our cutting-edge SaaS platform is designed to revolutionise the way businesses communicate with their customers. "
    },
    // {
    //     sliderhaed:"Create a Flow For Chatbot",
    //     sliderPara:"Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam."
    // },
    // {
    //     sliderhaed:"Automate the Chat Flow",
    //     sliderPara:"Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam"
    // }
]